<script lang="ts" setup>
import type { NavItemStatus } from '#tools/state/useRFQDetails'

useHead({
  title: 'Engage a Market Maker',
})
const rfqDetails = useRFQDetails()
await rfqDetails.fetch()

function getIcon(status: NavItemStatus) {
  switch (status) {
    case 'available':
    case 'completed':
      return 'i-heroicons-check-circle'
    case 'lock':
      return 'i-heroicons-lock-closed'
    case 'requoted':
      return 'i-heroicons-arrow-path'
    default:
      return ''
  }
}

const addCommandPalette = computed(() => {
  const { env } = useRuntimeConfig().public
  return env !== 'production'
})

function getColorClass(status: NavItemStatus) {
  return ['completed', 'requoted'].includes(status) ? 'text-green-600' : 'text-text-placeholder'
}
</script>

<template>
  <NuxtLayout name="dashboard" v-bind="$attrs">
    <template #header>
      <div class="flex gap-2 items-center">
        <div>
          <img
            :src="String('/icons/market-maker-monitoring.svg')"
            width="32"
            height="32"
            alt="Engage a Market Maker"
          >
        </div>
        <div class="text-sm font-semibold flex-shrink-0">
          Engage a Market Maker
        </div>
      </div>
    </template>

    <template #sidebar>
      <div class="h-full border-r border-forgd-bgd-600 w-[228px]">
        <nav
          aria-label="Exchange Listing Navigation"
          class="flex flex-col py-4 px-2 flex-shrink-0 sticky top-0 gap-1"
        >
          <UButton
            :to="rfqDetails.overviewNavItem.link"
            color="gray"
            variant="ghost"
            active-class="bg-neutral-400"
            class="w-full h-8.5 rounded hover:bg-neutral-400 p-2"
          >
            <span class="text-xs whitespace-nowrap text-gray-600 font-normal">
              {{ rfqDetails.overviewNavItem.label }}
            </span>
          </UButton>
          <div class="border-b border-forgd-bgd-600 my-3" />
          <span class="w-full text-xs font-normal text-neutral-700 mb-1">RFQ Progress Flow</span>
          <UButton
            v-for="navItem in rfqDetails.navItems"
            :key="navItem.label"
            :to="navItem.link"
            color="gray"
            variant="ghost"
            active-class="bg-forgd-bgd-400"
            class="w-full h-[34px] rounded hover:bg-forgd-bgd-400"
          >
            <div class="flex items-center text-xs whitespace-nowrap text-text-body gap-2 font-normal">
              <UIcon
                v-if="navItem.status !== 'none'"
                :key="navItem.status"
                :name="getIcon(navItem.status)"
                class="w-3.5 h-3.5"
                :class="getColorClass(navItem.status)"
              />
              {{ navItem.label }}
            </div>
          </UButton>
          <div class="border-b border-forgd-bgd-600 my-3" />
          <span class="w-full text-xs font-normal text-neutral-700 mb-1">Already have a Market Maker?</span>
          <UButton
            :to="rfqDetails.mmNavItem.link"
            color="gray"
            variant="ghost"
            active-class="bg-neutral-400"
            class="w-full h-8.5 rounded hover:bg-neutral-400 p-2"
          >
            <UIcon :key="rfqDetails.mmNavItem.status" :name="getIcon(rfqDetails.mmNavItem.status)" class="w-3.5 h-3.5" :class="getColorClass(rfqDetails.mmNavItem.status)" />
            <span class="text-xs whitespace-nowrap text-gray-600 font-normal">
              {{ rfqDetails.mmNavItem.label }}
            </span>
          </UButton>
        </nav>
      </div>
    </template>

    <div class="py-5">
      <slot />
    </div>
  </NuxtLayout>

  <MmCommandPalette
    v-if="addCommandPalette"
  />
</template>
